.gw-card-group {
    @include xl {
        li:not(:nth-child(3n)) {
            margin-right: 4.5%;
        }
    }
}

.gw-ul {
        li {
            @extend .hover-text-primary;
            padding: 10px;
        }
        a {
            @extend .bg-transparent;
            @extend .p-0;
            @extend .hover-text-primary;
        }
}

.gw-subUl {
    li {
        @extend .hover-text-primary;
    }
    a {
        @extend .bg-transparent;
        @extend .p-0;
        @extend .hover-text-primary;
    }
}