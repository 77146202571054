.ReactModal__Content {
    inset: 80px 40px 40px !important;
    @include md {
        width: 80%;
        left: 50% !important;
        transform: translateX(-50%);
    }
    @include lg {
        width: 70%;
    }
    padding: 0 !important;
}

.ecr-modal-content {
    padding: 40px;
}