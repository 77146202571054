.carousel {
    height: 400px;
    @include md {
        height: 500px;
    }
    @include lg {
        height: 640px;
    }
}

.homepage-card-text {
    @extend .card-text;
    -webkit-line-clamp: 3; /* 限制顯示的行數 */
}

.homepage-card-body {
    min-height: 8.5rem;
}