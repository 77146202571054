.card {
    @extend .cursor-pointer;
    @extend .mb-5;
}

.card-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-img-container, .MuiSkeleton-root.card-skeleton-img-container {
    height: 200px;
    @include xs {
        height: 250px;
    }
    @include sm {
        height: 318px;
    }
    @include md {
        height: 196px;
    }
    @include lg {
        height: 168px;
    }
    @include xl {
        height: 151px;
    }
    @include xxl {
        height: 181px;
    }
    @include xl3 {
        height: 208px;
    }
    @include xl4 {
        height: 266px;
    }
}

.card-body {
    min-height: 5rem;
}