.container {
    padding-right: 30px;
    padding-left: 30px;
    @include md {
        padding-left: 75px;
        padding-right: 75px;
    }
}

@media screen and (min-width: 1536px) {
    .container {
        max-width: 1480px;
    };
}

@media  screen and (min-width: 1920px) {
    .container {
        max-width: 1830px;
    };
}

.narrow-container {
    padding: 0 30px;
    @include md {
        padding: 0 75px;
    }
    @include lg {
        padding: 0 125px;
    }
    @include xl {
        padding: 0 200px;
    }
    @include xxl {
        padding: 0 250px;
    }
}