@mixin xs {
    @media (min-width: 475px) {
        @content;
    }
}

@mixin sm {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin md {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin lg {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: 1400px) {
        @content;
    }
}

@mixin xl3 {
    @media (min-width: 1536px) {
        @content;
    }
}

@mixin xl4 {
    @media (min-width: 1920px) {
        @content;
    }
}