.header-navbar {
    @include md {
        height: 90px;
    }
    @include lg {
        height: unset;
    }
}

.logo-link {
    // background-image: url("../../images/header/logo.png");
    // width: 211.87px;
    // height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    text-indent: 101%;
    overflow: hidden;
    white-space: nowrap;
    @include md {
        width: 142px;
        height: 54px;
    }
    @include lg {
        width: 211.87px;
        height: 50px;    
    }
}

.homepage-logo-link {
    // background-image: url("../../images/header/homepage-logo.png");
    // width: 211.87px;
    // height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    text-indent: 101%;
    overflow: hidden;
    white-space: nowrap;
    @include md {
        width: 142px;
        height: 54px;
    }
    @include lg {
        width: 211.87px;
        height: 50px;    
    }
}

.li-link {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    // @extend .hover-border-b-light-primary;
    // @extend .hover-text-light-primary;
    // @extend .border-2;
    // @extend .border-bottom;
    // @extend .border-black;
    @extend .cursor-pointer;
    @extend .px-30px;
    @include lg {
        flex-grow: 1;
    }
    // @include xl {
    //     font-size: 1.2rem;
    // }
    // @include xxl {
    //     font-size: 1.375rem;
    //     font-weight: 500;
    //     letter-spacing: 2.2px;
    // }
    a {
        border-bottom: 1px solid transparent;
        // animation: revealBorder 0.3s ease-in-out forwards;
        position: relative;
    }
}


.projects-subCategory-ul > li {
    padding: 0 !important;
}

.show-border::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #695C55;
    animation: revealBorder 0.3s ease-in-out forwards;
    // transition: width 0.3s ease-in-out;
}

// .show-border:hover::after {
//     animation: revealBorder 0.3s ease-in-out backwards;
// }


.hide-border::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: black;
    animation: revealBorder 0.3s ease-in-out backwards;
}

.hamburgerMenuBtn {
    @extend .p-0;
    height: 18px;
    @include md {
        height: 28px;
    }
}

.hamburgerMenuLine {
    @extend .bg-primary;
    height: 2px;
    width: 24px;
    @include md {
        width: 40px;
    }
}

.header-offcanvas {
    top: 56px !important;
    @include md {
        top: 90px !important;
    }
}

.header {
    min-height: 60px;
    position: sticky;
    top: 0;
    z-index: 100;
}

.header-ul {
    li {
        padding: 0 10px;
        color: white;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
    }
    a {
        @extend .bg-transparent;
        font-size: 0.9rem;
    }
}

.header-subUl {
    display: none;
    list-style-type: none;
    min-width: 195px;
    top: 50px;
    padding-top: 10px;
    background-color: black;
    > li {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }
}

.header-games-walkthrough-subUl {
    @extend .header-subUl;
    transform: translateX(-35%);
}

.header-popular-games-subUl {
    @extend .header-subUl;
    transform: translateX(-28%);
}

.header-game-system-providers-subUl {
    @extend .header-subUl;
    transform: translateX(-21%);
}

.header-games-walkthrough:hover .header-games-walkthrough-subUl,
.header-games-walkthrough .header-games-walkthrough-subUl:hover,
.header-popular-games:hover .header-popular-games-subUl,
.header-popular-games .header-popular-games-subUl:hover,
.header-game-system-providers:hover .header-game-system-providers-subUl,
.header-game-system-providers .header-game-system-providers-subUl:hover {
    @include lg {
        display: block;
    }
}

.offcanvas-body, .header {
    li {
        @extend .hover-text-primary;
        // @extend .cursor-pointer;
        padding: 10px;
    }
    a {
        @extend .bg-transparent;
        @extend .p-0;
        @extend .hover-text-primary;
    }
}